import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          `► Generative Fill`,
          "► Text to Image",
          "► Image to Animate",
          "► Paint to Image",
          "► Face Swap"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 100,

      }}
    />
  );
}

export default Type;
