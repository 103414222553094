import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import gplay from "../../Assets//google-play-badge.png";

import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
function Home() {
  // const handleClick = () => {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.sdc.cavinaai",
  //     "_blank"
  //   );
  // };

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading-name">
                <strong className="main-name">
                  {" "}
                  Cavina AI - Generative Fill
                </strong>
              </h1>

              <h1 className="heading-name">
                <span className="wave" role="img" aria-labelledby="wave">
                  ✨
                </span>{" "}
                Key Features
              </h1>

              <div
                style={{ paddingLeft: 80, paddingTop: 20, textAlign: "left" }}
              >
                <Type />
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.sdc.cavinaai"
                alt="new"
              >
                <img
                  src={gplay}
                  alt="home pic"
                  className="img-fluid"
                  style={{ maxHeight: "100px", paddingLeft: 80 }}
                />
              </a>
            </Col>

            <Col md={3} style={{ justifyContent: "center" }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px", alignSelf: "center" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
      <Particle />
    </section>
  );
}

export default Home;
