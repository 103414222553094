import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { TfiLocationPin } from "react-icons/tfi";
import { MdOutlineLocalPhone } from "react-icons/md";
import { AiOutlineNumber } from "react-icons/ai";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            We are a startup company from{" "}
            <span className="purple">Vietnam. </span>
            <br />
            <br />
            With a team with many years of experience in the field of AI and
            computer vision, we create high-quality and user-friendly products.
            <br />
            <br />
            In 2023, we released our first product, which entered the top 200
            highest-grossing apps on Google Play. In 2023, we released our first
            product, which entered the{" "}
            <span className="purple">
              top 200 highest-grossing apps on Google Play{" "}
            </span>
            <br />
            <br />
            <br />
            Contact us:
          </p>
          <ul>
            <li className="about-activity">
              <AiOutlineNumber /> 3502494460
            </li>
            <li className="about-activity">
              <TfiLocationPin /> 880/32 street 30/4 ward 11, Vung Tau city, Ba
              Ria Vung Tau province, Vietnam.
            </li>
            <li className="about-activity">
              <HiOutlineMail /> admin@sdcsoftware.name.vn
            </li>

            <li className="about-activity">
              <MdOutlineLocalPhone /> +84 352111811
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
