import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "4.6em" }}>Generative Fill</h1>
            <p className="home-about-body">
              <i>
                <b className="purple"> Select and Describe objects</b>
              </i>
              &nbsp; then it will be generated to the photo. Very appropriate to
              remove objects, change outfits, or add any scenery to the photo...
              With extremely accurate object selection support tools.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "4.6em" }}>Text to Image</h1>
            <p className="home-about-body">
              You can create any photo you want. You can create a story from
              photos, home screen, need photos to illustrate your
              presentation,.... <br />
              Express your creativity now!
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "4.6em" }}>Paint to Image</h1>
            <p className="home-about-body">
              You can create a wonderful picture with your own hands. By drawing
              the main details of the picture, AI can then create a complete
              picture!
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "4.6em" }}>Image to Animate</h1>
            <p className="home-about-body">
              From image, portraits, landscape photos, etc. They can turn into a
              vivid picture of another world. With smooth transition effects,
              Will be fun to share with friends.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "4.6em" }}>Face Swap</h1>
            <p className="home-about-body">
              Quickly change a person's appearance, you can transform into any
              character.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
